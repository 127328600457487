import React, { useState } from "react";
import Icon from "./icon";
import { CheckCircleIcon } from "@heroicons/react/solid";
import * as styles from "./signup.module.css";

function Signup() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [signedUp, setSignedUp] = useState(false);

  const updateEmail = (e) => {
    setEmail(e.target.value);
  };

  const submit = (e) => {
    console.log(e.target.value);
  };

  const isValidish = (text) => /.+(@).+\..+/.test(text);

  return (
    <div className={styles.signup}>
      <label className={styles.label}>
        Get updates
        <input
          className={styles.email}
          type="email"
          name="email"
          value={email}
          onChange={updateEmail}
        />
      </label>
      <button
        onClick={submit}
        disabled={!email.length || !isValidish(email)}
        className={styles.button}
      >
        <Icon>
          <CheckCircleIcon className={styles.confirm} />
        </Icon>
      </button>
    </div>
  );
}

export default Signup;
