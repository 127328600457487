import * as styles from "./blog-post-preview-grid.module.css";
import BlogPostPreview from "./blog-post-preview";
import { Link } from "gatsby";
import Signup from "./signup";
import React from "react";

function BlogPostPreviewGrid(props) {
  console.log(styles);
  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map((node, idx) => {
            if (idx === 2) {
              return (
                <div className={styles.gridThird}>
                  <Signup />
                </div>
              );
            }

            let className;
            if (idx === 0) className = styles.gridMain;
            else if (idx === 1) className = styles.gridSecond;
            else if (idx === 2) className = styles.gridThird;
            return (
              <li key={node.id} className={className}>
                <BlogPostPreview {...node} main={className} />
              </li>
            );
          })}
      </ul>
      {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref}>Browse more</Link>
        </div>
      )}
    </div>
  );
}

BlogPostPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

export default BlogPostPreviewGrid;
